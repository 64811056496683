import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Plugins
import { DateTime } from 'luxon';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng, options) => {
        if (format === 'uppercase') return value.toUpperCase();

        if (value instanceof Date && format && lng) {
          const date = DateTime?.fromJSDate(value)?.setLocale(lng);

          // @ts-ignore
          if (DateTime[format]) {
            // @ts-ignore
            return date.toLocaleString(DateTime[format]);
          }

          return date.toFormat(format);
        }

        if (format === 'currency') {
          const maximumFractionDigits = Math.min(20, Math.max(0, options?.maxFractionDigits || 0));

          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits,
            minimumFractionDigits: maximumFractionDigits
          }).format(value);
        }

        return value;
      }
    }
  });

export default i18n;
