import { useReducer, createContext, useContext, useMemo, useEffect } from 'react';

// Helpers
import * as api from 'utils/api';
import * as storage from 'utils/storage';
import filterStaleCache from 'utils/filterStaleCache';
import throwResponseError from 'utils/throwResponseError';

// Types
import { Order, Customer, ServerError, ErrorObject, Notification, GiftCard, Terms } from 'types';

interface UpdateUser {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  tcpaAgreed?: boolean;
  policiesAgreed?: boolean;
  mode: 'onboarding' | 'update';
}

interface UpdateUserWithValidation {
  userId: string;
  requestId: string;
  code: string;
}

interface UserState {
  user: Customer | null;
  jwt: string | null;
  credits: GiftCard[] | null;
  orders: {
    list: Order[];
    page: number;
    perPage: number;
    total: number | null;
  };
  order: Order | null;
  terms: Terms | null;

  requesting: { user: boolean; userCode: boolean; order: boolean; orders: boolean; credits: boolean; terms: boolean };
  notification: Notification | null;
}

interface UserProviderProps {
  userState: UserState;

  createUserPhoneCode: (payload: { phone: string; userId: string }) => Promise<any>;
  createUserEmailCode: (payload: { email: string; userId: string }) => Promise<any>;
  updateUser: (payload: UpdateUser) => Promise<any>;
  updateUserPhoneWithValidation: (payload: UpdateUserWithValidation) => Promise<any>;
  updateUserEmailWithValidation: (payload: UpdateUserWithValidation) => Promise<any>;
  setUser: (payload: { user: Customer; jwt: string }) => void;

  getOrder: (payload: { orderId: string; includePreview?: boolean }) => Promise<any>;
  getOrders: (payload: { page: number; perPage: number }) => Promise<any>;
  getCredits: () => Promise<any>;
  getCurrentTerms: () => Promise<any>;

  resetUserState: () => void;
}

// Constants
const PD_USER_SESSION = import.meta.env.VITE_USER_SESSION!;
const JWT_TOKEN = import.meta.env.VITE_JWT_TOKEN!;

const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';
const SET_NOTIFICATION = 'SET_NOTIFICATION';

const UPDATE_USER = 'UPDATE_USER';

const SET_USER = 'SET_USER';
const SET_ORDER = 'SET_ORDER';
const SET_ORDERS = 'SET_ORDERS';
const SET_CREDITS = 'SET_CREDITS';
const SET_TERMS = 'SET_TERMS';

const RESET_STATE = 'RESET_STATE';

const initialState = {
  user: null,
  jwt: null,
  credits: null,
  orders: {
    list: [],

    page: 1,
    perPage: 20,
    total: null
  },
  order: null,
  terms: null,

  requesting: { user: false, userCode: false, order: false, orders: false, credits: false, terms: false },
  notification: null
};

export const UserContext = createContext<UserProviderProps>({
  userState: initialState,

  createUserPhoneCode: () => Promise.resolve(),
  createUserEmailCode: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  updateUserPhoneWithValidation: () => Promise.resolve(),
  updateUserEmailWithValidation: () => Promise.resolve(),
  setUser: () => null,

  getOrder: () => Promise.resolve(),
  getOrders: () => Promise.resolve(),
  getCredits: () => Promise.resolve(),

  getCurrentTerms: () => Promise.resolve(),

  resetUserState: () => null
});

const reducer = (state: UserState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_NOTIFICATION:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_USER:
      return {
        ...state,
        user: payload.user,
        jwt: payload.jwt,
        notification: null
      };

    case UPDATE_USER:
      return {
        ...state,
        user: payload.data,
        notification: payload.notification
      };

    case SET_ORDERS:
      return {
        ...state,
        orders: payload.orders,
        notification: payload.notification
      };

    case SET_ORDER:
      return {
        ...state,
        order: payload.data,
        notification: payload.notification
      };

    case SET_CREDITS:
      return {
        ...state,
        credits: payload.data
      };

    case SET_TERMS:
      return {
        ...state,
        terms: payload.data
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used as HOC Wrapper around Routes
export const UserProvider = (props: { children: React.ReactNode }) => {
  const STORAGE_TYPE_IS_LOCAL = true;
  const CACHE_HOURS = import.meta.env.VITE_CACHE_HOURS || '24';
  const cacheHours = isNaN(parseInt(CACHE_HOURS)) ? 24 : parseInt(CACHE_HOURS);

  const createInitialState = (initialState: UserState): UserState => {
    const maxAge = 60 * 60 * cacheHours;
    const localSession = storage.get({ key: PD_USER_SESSION, isLocal: STORAGE_TYPE_IS_LOCAL });
    const localSessionParsed = localSession ? filterStaleCache({ cache: localSession, maxAge }) : null;

    return { ...initialState, ...localSessionParsed };
  };

  const [userState, dispatch] = useReducer(reducer, initialState, createInitialState);

  // Actions
  const setUser = (payload: { user: Customer; jwt: string }) => {
    const { user, jwt } = payload;

    // Store jwt in sessionStorage
    storage.set({ key: JWT_TOKEN, value: jwt, isLocal: STORAGE_TYPE_IS_LOCAL });

    dispatch({ type: SET_USER, payload: { user, jwt } });
  };

  const createUserPhoneCode = async (payload: { phone: string; userId: string }) => {
    const { userId, phone } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { userCode: true } });

      const response = await api.put({
        resource: `c/customers/${userId}/phone`,
        bodyPayload: { phone }
      });

      return await response
        .json()
        .then((data: any) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error, key: data.error_key };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { userCode: false } });
    }
  };

  const createUserEmailCode = async (payload: { email: string; userId: string }) => {
    const { email, userId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { userCode: true } });

      const response = await api.put({ resource: `c/customers/${userId}/email`, bodyPayload: { email } });

      return await response
        .json()
        .then((data: any) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error, key: data.error_key };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { userCode: false } });
    }
  };

  const updateUserPhoneWithValidation = async (payload: UpdateUserWithValidation) => {
    const { userId, code, requestId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { user: true } });

      const bodyPayload = {
        code,
        request_id: requestId
      };

      const response = await api.post({ resource: `c/customers/${userId}/phone/verify`, bodyPayload });

      return await response
        .json()
        .then((data: Customer & ServerError) => {
          // Catch error
          if (data.error) {
            const isCodeError = data?.error_key?.includes('incorrect_code');
            const message = isCodeError ? 'Invalid code, please try again' : data?.error_localized || data.error;
            const errorObject = { code: response.status, message };

            throw errorObject;
          }

          dispatch({ type: UPDATE_USER, payload: { data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { user: false } });
    }
  };

  const updateUserEmailWithValidation = async (payload: UpdateUserWithValidation) => {
    const { userId, code, requestId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { user: true } });

      const bodyPayload = {
        code,
        request_id: requestId
      };

      const response = await api.post({ resource: `c/customers/${userId}/email/verify`, bodyPayload });

      return await response
        .json()
        .then((data: Customer & ServerError) => {
          // Catch error
          if (data.error) {
            const isCodeError = data?.error_key?.includes('incorrect_code');
            const message = isCodeError ? 'Invalid code, please try again' : data?.error_localized || data.error;
            const errorObject = { code: response.status, message };

            throw errorObject;
          }

          dispatch({ type: UPDATE_USER, payload: { data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { user: false } });
    }
  };

  const updateUser = async (payload: UpdateUser) => {
    const { userId, firstName, lastName, email, tcpaAgreed, policiesAgreed, mode } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { user: true } });

      const bodyPayload = {
        ...(firstName ? { first_name: firstName } : {}),
        ...(lastName ? { last_name: lastName } : {}),
        ...(email ? { email } : {}),
        ...(tcpaAgreed !== undefined ? { tcpa_agreed: tcpaAgreed } : {}),
        ...(policiesAgreed ? { policies_agreed: policiesAgreed } : {})
      };

      const response = await api.put({ resource: `c/customers/${userId}${mode === 'onboarding' ? '/onboarding' : ''}`, bodyPayload });

      return await response
        .json()
        .then((data: Customer & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error, key: data.error_key };

            throw errorObject;
          }

          dispatch({ type: UPDATE_USER, payload: { data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { user: false } });
    }
  };

  const getOrders = async (payload: { page: number; perPage: number }) => {
    const { page, perPage } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { orders: true } });

      const response = await api.get({
        resource: `orders`,
        urlParams: { page, per_page: perPage, order: 'created_at', dir: 'DESC' }
      });

      return await response
        .json()
        .then((data: Order[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const headers: any = {};

          for (let pair of response.headers.entries()) headers[pair[0]] = pair[1];

          const orders = {
            list: data,
            page: Number(headers['x-page']),
            perPage: Number(headers['x-per-page']),
            total: Number(headers['x-total'])
          };

          dispatch({ type: SET_ORDERS, payload: { orders } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { orders: false } });
    }
  };

  const getOrder = async (payload: { orderId: string }) => {
    const { orderId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { order: true } });
      const response = await api.get({
        resource: `orders/${orderId}?include_preview=true`
      });

      return await response
        .json()
        .then((data: Order[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_ORDER, payload: { data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { order: false } });
    }
  };

  const getCredits = async () => {
    try {
      dispatch({ type: SET_REQUESTING, payload: { credits: true } });

      const response = await api.get({ resource: 'gift-cards', urlParams: { page: 1, per_page: 10000 } });

      return await response
        .json()
        .then((data: GiftCard[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_CREDITS, payload: { data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { credits: false } });
    }
  };

  const getCurrentTerms = async () => {
    try {
      dispatch({ type: SET_REQUESTING, payload: { terms: true } });

      const response = await api.get({ resource: 'terms/current' });

      return await response.json().then((data: Terms[] & ServerError) => {
        // Catch error
        if (data.error) {
          const errorObject = { code: response.status, message: data.error_localized || data.error };

          throw errorObject;
        }

        const currentTerms = data.find((terms: Terms) => terms.key === 'policies_agreed') || null;

        dispatch({ type: SET_TERMS, payload: { data: currentTerms } });

        return data;
      });
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { terms: false } });
    }
  };

  const resetUserState = () => dispatch({ type: RESET_STATE });

  useEffect(() => {
    // Persist data to local storage when necessary
    const localSession = storage.get({ key: PD_USER_SESSION, isLocal: STORAGE_TYPE_IS_LOCAL });
    let userSessionTime = Date.now();

    if (localSession) {
      try {
        const localSessionParsed = JSON.parse(localSession);

        if (localSessionParsed.time) userSessionTime = localSessionParsed.time;
      } catch (error) {
        console.error('Error parsing localSession:', error);
      }
    }

    storage.set({
      key: PD_USER_SESSION,
      value: JSON.stringify({ user: userState.user, jwt: userState.jwt, notification: null, time: userSessionTime }),
      isLocal: STORAGE_TYPE_IS_LOCAL
    });
  }, [userState]);

  const providerValue = useMemo(
    () => ({
      userState,
      setUser,
      createUserPhoneCode,
      createUserEmailCode,
      updateUser,
      updateUserPhoneWithValidation,
      updateUserEmailWithValidation,
      getOrder,
      getOrders,
      getCredits,
      getCurrentTerms,
      resetUserState
    }),
    [userState]
  );

  return <UserContext.Provider value={providerValue}>{props.children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider ');
  }

  return context;
};
